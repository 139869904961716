import { defaultStateContent } from "../../base/Button/ButtonState";
import { AlertPrompt } from "../../feature/AlertPrompt/AlertPrompt";
import Spacer from "../../layout/Spacer/Spacer";

export const ErrorMessage = () => (
  <div>
    <Spacer />
    <AlertPrompt style="error" title="error">{ defaultStateContent.error } </AlertPrompt>
  </div>
);
