import { useRouter } from "next/navigation";
import { FC, useState } from "react";

import { DisplayState } from "@/constants/state";
import { useAPI } from "apps/website/hooks/useAPI";

import Button from "../../base/Button/Button";
import Container from "../../layout/Container/Container";

// to do - change
export interface ApplyDiscountButtonProps {
  discountPercentage: string | null | undefined;
  discountCode: string | null;
  catName: string | null;
  catSex: string | null;
}

const ApplyDiscountButton: FC<ApplyDiscountButtonProps> = ({ discountPercentage, discountCode, catName = "your cat", catSex = "their" }) => {

  const [ buttonState, setButtonState ] = useState<DisplayState>(
    DisplayState.READY,
  );

  const api = useAPI();
  const router = useRouter();

  const applyDiscount = async () => {
    setButtonState(DisplayState.LOADING);

    try {
      // to do, change this
      const result = await api.Discounts.applyDiscount(discountCode!);
      console.log("result", result);
      if (result) {
        void router.push(`/discounts/applied?cat=${catName}&discountPercentage=${discountPercentage?.toString()}&catSex${catSex}`);
      }

    } catch (error) {
      console.log("error", error);
      setButtonState(DisplayState.ERROR);
      void router.push(`/discounts/failed?cat=${catName}&discountPercentage=${discountPercentage?.toString()}&catSex${catSex}&discountCode=${discountCode}`);
    }
  };

  return (
    <Container size="sm" padding="none">
      <div className="flex flex-col items-center justify-center gap-y-4">
        <Button className="w-[280px]" onClick={applyDiscount} state={buttonState}>
          { `Get ${discountPercentage}% OFF` }
        </Button>
      </div>
    </Container>
  );
};

export default ApplyDiscountButton;
