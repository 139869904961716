import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import { LoadingSpinner } from "../../form/LoadingSpinner/LoadingSpinner";

interface ISectionLoadingSpinnerProps {
  theme?: Theme;
}

const SectionLoadingSpinner: FC<ISectionLoadingSpinnerProps> = ({ theme = "light-grey" }) => (
  <Section component={SectionLoadingSpinner.name} theme={theme}>
    <Container>
      <Grid>
        <Column align="center" justify="center">
          <LoadingSpinner color="black" />
        </Column>
      </Grid>
    </Container>
  </Section>
);

export default SectionLoadingSpinner;
