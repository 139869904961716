"use client";

import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouter, useSearchParams } from "next/navigation";

import { DisplayState } from "@/constants/state";
import { IFreshSubDiscountCalculations } from "@/discount-calculation";
import { useAPI } from "apps/website/hooks/useAPI";
import { useAuth, useDatadog } from "@auth/client-sdk-react";

import Section from "../../layout/Section/Section";
import Column from "../../layout/Column/Column";
import Grid from "../../layout/Grid/Grid";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import TextBody from "../../base/Text/TextBody/TextBody";
import Spacer from "../../layout/Spacer/Spacer";
import Container from "../../layout/Container/Container";
import SectionLoadingSpinner from "../SectionLoadingSpinner/SectionLoadingSpinner";
import Icon from "../../base/Icon/Icon";

import { ErrorMessage } from "./Error";
import ApplyDiscountButton from "./ApplyDiscountButton";

export interface ISectionCancellationDiscountProps {
  discountCode: string
}

const SectionCancellationDiscount: FC<ISectionCancellationDiscountProps> = ({ discountCode }) => {

  const api = useAPI();
  const { accessToken } = useAuth();

  const [ discounts, setDiscounts ] = useState<IFreshSubDiscountCalculations>();
  const [ formState, setFormState ] = useState<DisplayState>(
    DisplayState.LOADING,
  );

  const datadog = useDatadog();
  const router = useRouter();
  const params = useSearchParams();

  const catName = useMemo(() => params.get("cat"), [ params ]);
  const catSex = useMemo(() => params.get("cat_sex"), [ params ]);

  const pronoun = useMemo(() => (catSex === "BOY" ? "he" : "she"), [ catSex ]);

  const getDiscountCalculations = useCallback(async ():

  Promise<IFreshSubDiscountCalculations | undefined> => {

    const subId = params.get("subscriptionId");

    if (!subId || Number.isNaN(+(subId))) {
      void router.push("/discounts/failed");
      return;
    }

    const { data } = await api.Discounts.getDiscountCalculations(+subId, discountCode);
    if (!data) { throw new Error(); }
    return data;

  }, [ accessToken, discountCode, params ]);

  const getDiscountEstimation = async () => {
    try {
      const result = await getDiscountCalculations();
      console.log("result", result);
      if (result) {

        datadog.logger.info("adsfa", result);
        setDiscounts(result);
        setFormState(DisplayState.READY);
      }

    } catch (error) {
      console.log("error", error);
      setFormState(DisplayState.ERROR);
    }
  };

  useEffect(() => {
    if (accessToken) {
      // is it necessary to extract into function?
      void getDiscountEstimation();
    }
  }, [ accessToken ]);

  return (

    <div className="p-2">
      { formState === DisplayState.LOADING ? (
        <SectionLoadingSpinner theme="light-grey" />
      ) :

        <Section component={SectionCancellationDiscount.name} >
          <Container padding="none" >

            <TextSubtitle size={1} align="center">
              GET { discounts?.percentageSaved?.toString() }% OFF YOUR NEXT BOX
            </TextSubtitle>

            <Spacer size={"md"}></Spacer>
            <div className="flex justify-center space-x-1">
              <TextBody size={1} align="center" className="line-through text-dark-grey" tag="span" >
                £{ discounts?.originalPrice.toString() }
              </TextBody>
              <TextBody size={1} align="center" tag="span">
                £{ discounts?.discountedPrice.toString() }
              </TextBody>
            </div>
          </Container>

          <Spacer size={"lg"}></Spacer>

          <Container padding="none" className="bg-white">
            <Grid theme="light-grey">
              <div className="flex gap-2 col-span-12 justify-center p-2">

                <Column spans={{ default: 2, lg: 6 }}>
                  <Icon icon="coins" className="w-16 h-16 lg:w-20 lg:h-20"></Icon>

                </Column>
                <Column spans={{ default: 10, lg: 6 }} className="gap-y-2" >
                  <TextSubtitle size={2} align={{ initial: "default", lg: "center" }}>
                    Give { catName } time to get back on track
                  </TextSubtitle>
                  <TextBody tag='div' size={2} align={{ initial: "default", lg: "center" }}>
                    <p>
                      We get it. It&apos;s hard when { pronoun } doesn&apos;t devour { pronoun === "he" ? "his" : "her" } food.
                    </p>
                    <p>
                      But we know you want the best for { pronoun === "he" ? "him" : "her" }.
                      And so do we. So here&apos;s { discounts?.percentageSaved?.toString() }% off your next box.
                      (That&apos;s a £ { discounts?.savings?.toString() } saving.)
                    </p>
                    <p>
                      Because we&apos;re beside you on the journey to { catName }&apos;s best health.
                    </p>
                  </TextBody>
                </Column>
              </div>
            </Grid>

          </Container>
          <Spacer />
          <ApplyDiscountButton
            discountPercentage={discounts?.percentageSaved?.toString()}
            discountCode={discountCode}
            catName={catName}
            catSex={catSex} />
          { formState === DisplayState.ERROR && <ErrorMessage /> }
        </Section>
      }</div>
  );
};

export default SectionCancellationDiscount;
